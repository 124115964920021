<template>
  <div class="">
    <div @click="printpage()" style="margin-top:100rem;background:#fff;font-size:20rem;">打印</div>
    <div style="text-align:left;" id="printContent">
      <div style="display:flex;align-items:center;">
        <p style="flex:1;font-size:18rem;">打印内容</p>
        <p>嘿嘿哈嘿</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {

  },
  components: {

  },
  data () {
    return {

    }
  },
  computed: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    printpage () {
      console.log('打印---')
      var newstr = document.getElementById('printContent').innerHTML
      // var oldstr = document.body.innerHTML
      document.body.innerHTML = newstr
      window.print()
      // document.body.innerHTML = oldstr
      location.reload()
      // return false
    }
  }
}
</script>

<style scoped lang="scss">

</style>
